import { useState } from 'react';
import { Formik } from 'formik';
import {
  FormikWizardProvider,
  Wizard,
  StepsList,
  Step,
  ButtonsList,
  PreviousButton,
  NextButton,
  SubmitButton,
} from 'formik-wizard-form';
import PropTypes from 'prop-types';

import { sendWorkReqeust, uploadBookingDetailsImage } from 'api';

import Button from 'components/ui-kit/buttons/button';
import SentRequestConfirmationBlock from 'components/sent-request-confirmation-block';

import { useApp, useModal, useToast } from 'hooks';
import { getTimezoneOffset } from 'helpers/datetime.helper';

import getSteps from './steps';
import stepsValidation from './stepsValidation';
import { buildFormikValuesEntries } from './utils';

import validationSchema from './schema';

import * as S from './styles';

const RequestWorkForm = ({ form }) => {
  const { hideModal } = useModal();
  const { portal } = useApp();
  const { addErrorToast } = useToast();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const getCustomFields = () => form?.fields.filter(({ isEditable }) => isEditable);

  const getInitialValues = () => {
    const customFieldsDefaultValues = buildFormikValuesEntries(getCustomFields().map((field) => ({ question: field })));

    return {
      date: '',
      customTimeRangeEnabled: !!form.customTimeRange,
      servicesEnabled: form?.services?.length > 0,
      portalId: portal.id,
      time: '',
      serviceIds: [],
      address: '',
      geoData: '',
      unitNumber: '',
      isAddressInArea: '',
      ...customFieldsDefaultValues,
    };
  };

  const getValidatorsWithCustomFields = () => {
    const bookingDetailsStepIndex = 1;
    const validators = stepsValidation;
    const customFieldsNames = getCustomFields().map(({ name }) => name);

    const BookingDetailsStep = ({ errors }) => {
      const errorsArray = customFieldsNames.map((name) => errors[name]);

      return !errorsArray.some((error) => error);
    };

    validators[bookingDetailsStepIndex] = BookingDetailsStep;

    return validators;
  };

  const submit = async (values) => {
    const customFieldNames = getCustomFields()
      .map(({ name, labelText }) => { return { name, labelText }; });

    const customAnswers = await Promise.all(
      customFieldNames.map(async ({ name, labelText }) => {
        const value = values[name];

        if (value instanceof File) {
          const { data: { imageUrl } } = await uploadBookingDetailsImage(portal.id, value);

          return {
            name,
            label: labelText,
            answers: [imageUrl],
          };
        }

        return {
          name,
          label: labelText,
          answers: Array.isArray(value) ? value.filter(Boolean) : [value],
        };
      }),
    );

    const selectedDate = values.date ? new Date(values.date) : null;

    const data = {
      serviceIds: values.serviceIds || [],
      time: values.time,
      date: selectedDate
        ? new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0))
        : null,
      timezoneOffset: getTimezoneOffset(),
      customFields: customAnswers,
      address: {
        location: values.address,
        unitNumber: values.unitNumber,
        geoData: {
          latitude: Number(values.geoData.latitude),
          longitude: Number(values.geoData.longitude),
          placeId: values.geoData.placeId,
        },
      },
    };

    const result = await sendWorkReqeust(portal.id, data);

    if (result.status === 200) {
      setShowConfirmation(true);
    } else {
      addErrorToast('Something went wrong during sending the work request. You can try again later.');
    }
  };

  return (
    <S.Content>
      {!showConfirmation && (
        <Formik
          enableReinitialize
          validateOnBlur
          initialValues={getInitialValues()}
          validationSchema={validationSchema(getCustomFields())}
          onSubmit={submit}
        >
          {(formikProps) => (
            <FormikWizardProvider {...formikProps}>
              {({ getValidators, ...restProps }) => (
                <Wizard {...restProps} className="wizard">
                  <StepsList validators={getValidators(getValidatorsWithCustomFields())}>
                    {getSteps(formikProps?.values?.servicesEnabled).map((step, i) => (
                      <Step
                        key={`step-${String(i)}`}
                        component={step.component}
                        title={step.title}
                        form={form}
                        setFieldTouched={formikProps.setFieldTouched}
                      />
                    ))}
                  </StepsList>

                  <ButtonsList>
                    <PreviousButton>
                      <Button w={90} h={48}>Back</Button>
                    </PreviousButton>

                    <NextButton>
                      <Button w={90} h={48} color="primary">Next</Button>
                    </NextButton>

                    <SubmitButton>
                      <S.Button>
                        <Button
                          type="submit"
                          color="primary"
                          stretched
                          isLoading={formikProps.isSubmitting}
                        >
                          Submit
                        </Button>
                      </S.Button>
                    </SubmitButton>
                  </ButtonsList>
                </Wizard>
              )}
            </FormikWizardProvider>
          )}
        </Formik>
      )}

      {showConfirmation && <SentRequestConfirmationBlock onSubmit={() => { hideModal(); }} />}

      <S.Logo>
        <S.Text>Powered by</S.Text>

        <S.LogoImage />
      </S.Logo>
    </S.Content>
  );
};

RequestWorkForm.propTypes = {
  form: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
    services: PropTypes.arrayOf(PropTypes.shape({})),
    customTimeRange: PropTypes.number,
    workingSchedule: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default RequestWorkForm;
