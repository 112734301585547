import BookingDetailsStep from './booking-details-step';
import ContactDetailsStep from './contact-details-step';
import ServicesStep from './services-step';

const STEP_TYPES = {
  SERVICES: 'Services',
  BOOKING_DETAILS: 'Booking details',
  CONTACT_DETAILS: 'Contact details',
};

const STEP_COMPONENTS = {
  [STEP_TYPES.SERVICES]: ServicesStep,
  [STEP_TYPES.BOOKING_DETAILS]: BookingDetailsStep,
  [STEP_TYPES.CONTACT_DETAILS]: ContactDetailsStep,
};

const getSteps = (servicesEnabled) => {
  let steps = [
    { type: STEP_TYPES.SERVICES },
    { type: STEP_TYPES.BOOKING_DETAILS },
    { type: STEP_TYPES.CONTACT_DETAILS },
  ];

  if (!servicesEnabled) {
    steps = steps.filter((step) => step.type !== STEP_TYPES.SERVICES);
  }

  return steps.map((step, index) => ({
    component: STEP_COMPONENTS[step.type],
    title: `${index + 1}. ${step.type}`,
  }));
};

export default getSteps;
