import React from 'react';
import {
  Field as FormikField,
  FieldArray,
  ErrorMessage,
} from 'formik';
import PropTypes from 'prop-types';

import {
  Textarea,
  Radio,
  Checkbox,
} from 'components/ui-kit/form';
import { Typography, Stack } from 'components/ui-kit';
import SimplePhotoUploader from 'components/simple-photo-uploader';

import { bookingFormFieldType } from 'constants/appointments';

import * as S from '../styles';

const BookingDetailsStep = ({
  form,
  setFieldTouched,
}) => {
  const customFieds = form.fields.filter((f) => f.isEditable);

  const textAreaComponent = (field) => (
    <FormikField
      name={field.name}
      label={field.placeHolder}
      component={Textarea}
      isGap={false}
    />
  );

  const checkboxComponent = (field) => (
    <FieldArray name={field.name}>
      <S.CheckboxRadioWrapper>
        {field.options.map((option, index) => (
          <FormikField name={`${field.name}[${index}]`} key={`${String(index)}`}>
            {(fieldProps) => (
              <S.FieldWrapper>
                <Checkbox
                  {...fieldProps}
                  textValue={option}
                  size={18}
                  gap={false}
                  onChange={() => setFieldTouched(field.name, true)}
                >
                  <S.FieldText>
                    {option}
                  </S.FieldText>
                </Checkbox>
              </S.FieldWrapper>
            )}
          </FormikField>
        ))}

        <S.ErrorContainer>
          <ErrorMessage name={field.name} render={(msg) => <S.Error>{msg}</S.Error>} />
        </S.ErrorContainer>
      </S.CheckboxRadioWrapper>
    </FieldArray>
  );

  const radioComponent = (field) => (
    <S.CheckboxRadioWrapper>
      {field.options.map((option, index) => (
        <FormikField name={field.name} key={`${String(index)}`}>
          {(fieldProps) => (
            <S.FieldWrapper>
              <Radio
                {...fieldProps}
                id={`${field.name}-${option}`}
                textValue={option}
              >
                <S.FieldText>
                  {option}
                </S.FieldText>
              </Radio>
            </S.FieldWrapper>
          )}
        </FormikField>
      ))}

      <S.ErrorContainer>
        <ErrorMessage name={field.name} render={(msg) => <S.Error>{msg}</S.Error>} />
      </S.ErrorContainer>
    </S.CheckboxRadioWrapper>
  );

  const bookingFormImageField = (field) => {
    return (
      <Stack gap={16}>
        <Typography fontSize={13} font="regular" color="secondary300">
          Recommended image format: 400x400px PNG or JPG file.
        </Typography>

        <FormikField
          name={field.name}
          fieldName={field.name}
          component={SimplePhotoUploader}
          type="Online Booking"
          size="160px"
          gap={0}
        />
      </Stack>
    );
  };

  const fieldToComponent = (field) => {
    switch (field.fieldType) {
      case bookingFormFieldType.checkbox:
        return checkboxComponent(field);
      case bookingFormFieldType.radio:
        return radioComponent(field);
      case bookingFormFieldType.image:
        return bookingFormImageField(field);
      default:
        return textAreaComponent(field);
    }
  };

  return (
    <S.Content>
      <S.Header>
        Book Online
      </S.Header>

      {customFieds.map((field, index) => (
        <S.FieldContainer key={`${String(index)}`}>
          <S.Label>
            {field.labelText}
            &nbsp;
            {field.isRequired && '*'}
          </S.Label>

          {fieldToComponent(field)}
        </S.FieldContainer>
      ))}
    </S.Content>
  );
};

const onlineBookingFormFieldItemPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  fieldType: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  ),
  isRequired: PropTypes.bool.isRequired,
});

BookingDetailsStep.propTypes = {
  form: PropTypes.shape({
    fields: PropTypes.arrayOf(onlineBookingFormFieldItemPropTypes).isRequired,
  }).isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
};

export default BookingDetailsStep;
